import './App.css';
import React, {Suspense, useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { WishlistContext } from './store/wishlistContextProvider';
import { CounterContext } from './store/counterContextProvider';

const Login = React.lazy(() => import("./components/login/Login"));
const Loading = React.lazy(() => import("./components/Loading/Loading"));
const MainConsults = React.lazy(() => import("./components/consults/MainConsults"));
const MainOrders = React.lazy(() => import("./components/orders/MainOrders"));
const OrderList = React.lazy(() => import("./components/orders/OrderList"));
const OneOrder = React.lazy(() => import("./components/orders/OneOrder"));
const InvoiceList = React.lazy(() => import("./components/invoice/InvoiceList"));
const OneInvoice = React.lazy(() => import("./components/invoice/OneInvoice"));
const ProtectedRoute = React.lazy(() => import("./components/protection-routes/ProtectedRoute"));

function App() {

  const [wishlist, setWishlist] = useState([]);
  const [counterShopchart, setCounterShopchart] = useState(wishlist.length);

  return (
    <>
        <BrowserRouter>
          <WishlistContext.Provider value={{ wishlist, setWishlist }} >
          <CounterContext.Provider value={{ counterShopchart, setCounterShopchart }} >
            <Suspense fallback={<Loading />} >
              <Routes>
                <Route index element={<Login />} />  
                <Route path='/' element={<Login />} /> 
                <Route element={<ProtectedRoute />}>
                  <Route path='/consultas' element={<MainConsults />} />
                  <Route path='/pedido' element={<MainOrders />} />
                  <Route path='/lista-pedidos' element={<OrderList />} />
                  <Route path='/info-pedido/:orderid' element={<OneOrder />} />  
                  <Route path='/lista-albaranes' element={<InvoiceList />} />  
                  <Route path='info-albaran/:invoiceid' element={<OneInvoice />} />
                </Route>
              </Routes>
            </Suspense>
          </CounterContext.Provider>
          </WishlistContext.Provider>
        </BrowserRouter>
    </>
  );
}

export default App;
